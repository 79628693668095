import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IReverseSkin } from '../../../common/model/graphql-types';
import { ReverseSkinCard } from './rev-skin-card';

interface IProps {
  id?: string;
  slug: string;
  showLabel?: boolean;
  mode: string;
  unitId?: string;
}

export const Reverse1999Skin: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug
}) => {
  const allReverseSkin = useStaticQuery(graphql`
    query {
      allContentfulReverseSkin {
        nodes {
          id
          skinId
          name
          characterName
          slug
          rarity
          type
          availableInGlobal
          patch
          imageCard {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const character = allReverseSkin.allContentfulReverseSkin.nodes.find(
    (emp: IReverseSkin) =>
      id ? emp.id === id : unitId ? emp.skinId === unitId : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'card' && (
        <ReverseSkinCard employee={character} showLabel={showLabel} />
      )}
    </>
  );
};
