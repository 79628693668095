import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IReverseSkin } from '../../../common/model/graphql-types';

interface IProps {
  employee: IReverseSkin;
  showLabel?: boolean;
}

export const ReverseSkinCard: React.FC<IProps> = ({ employee, showLabel }) => {
  const image = employee.imageCard
    ? getImage(employee.imageCard.localFile.childImageSharp)
    : null;
  return (
    <Link to={'/re1999/skins/' + employee.slug}>
      <div
        className={`avatar reverse rarity-${employee.rarity} ${
          showLabel ? showLabel : ''
        }`}
      >
        <div className="overlay"></div>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
        {showLabel && <span className="emp-name">{employee.name}</span>}
      </div>
      {showLabel && !employee.availableInGlobal && (
        <span className="tag future">
          {employee.patch ? employee.patch : '?'}
        </span>
      )}
    </Link>
  );
};
