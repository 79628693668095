import React from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IReverseSkin } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './rev-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { RevRarity } from '../modules/reverse/common/components/rev-rarity';
import { Reverse1999Skin } from '../modules/reverse/common/components/rev-skin';

interface IReverseCharacterNodes {
  nodes: IReverseSkin[];
}

interface IReverseCharacterEntry {
  currentUnit: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReverseSkinDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  const fullImage = character.imageFull
    ? getImage(character.imageFull.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-reverse '}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/skins">Skins</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        <Reverse1999Skin mode="card" slug={character.slug} />
        <div className="character-details">
          <h1>{character.name}</h1>
          <div className="character-roles-and-types">
            <RevRarity rarity={character.rarity} />
          </div>
        </div>
      </div>
      <div id="section-profile" className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Skin information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Character Name</div>
              <div className="details">{character.characterName}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}✦</div>
            </div>
          </Col>
          <Col>
            <h5>Source information</h5>
            <div className="info-list-row">
              <div className="category">Source</div>
              <div className="details">{character.type}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Available in Global?</div>
              <div className="details">
                {character.availableInGlobal ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="info-list-row">
              <div className="category">Patch</div>
              <div className="details">
                {character.patch ? character.patch : '-'}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div id="section-gallery" className="id-section">
        <SectionHeader title="Gallery" />
        <h5>Full design</h5>
        {fullImage ? (
          <GatsbyImage image={fullImage} alt="" className="full-image" />
        ) : (
          <Alert variant="primary">
            <Alert.Heading>
              No full image is not available for this Skin yet
            </Alert.Heading>
            <p>We will add it as soon as it is possible!</p>
          </Alert>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ReverseSkinDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Reverse 1999 | Prydwen Institute'}
      description={
        'Discover the information about ' +
        character.name +
        ' skin in Reverse 1999.'
      }
      image={character.imageFull}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulReverseSkin(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...ReverseSkinFieldsFragment
      }
    }
  }
`;
