import React from 'react';

import './rev-boxes.scss';

interface IProps {
  rarity: string;
}

export const RevRarity: React.FC<IProps> = ({ rarity }) => {
  return <div className={`rev-rarity rarity-${rarity}`}>{rarity}✦</div>;
};
